<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<a-table :rowSelection="rowSelection" :columns="columns" :dataSource="localOutboundOrder.order" v-if="localOutboundOrder.order">
				<div slot="quantity" slot-scope="record">
					<a-input v-mask="'##'" v-model="record.quantity" />
				</div>
				<div class="text-left" slot="productLabel" slot-scope="record">
					{{ record.product.label }}
				</div>
			</a-table>
		</a-spin>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'
import Swal from 'sweetalert2'
import _ from 'lodash'
import utilities from '@/services/utilities'

export default {
	name: 'returnOrderComponent',
	directives: {
		mask,
	},
	computed: {
		...mapGetters('warehouse', ['spinnerLoaderLabel', 'spinnerLoader', 'actualOutboundOrder']),
	},
	data() {
		return {
			localOutboundOrder: {},
			columns: [
				{
					title: 'Cantidad',
					scopedSlots: { customRender: 'quantity' },
					align: 'center',
					width: '15%',
				},
				{
					title: 'Producto',
					scopedSlots: { customRender: 'productLabel' },
					align: 'center',
					width: '75%',
				},
			],
			selectedRecord: {},
			rowSelection: {
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRecord = _.cloneDeep({ selectedRowKeys, selectedRows })
				},
			},
		}
	},
	methods: {
		cleanLocal() {
			this.localOutboundOrder = {}
			this.selectedRecord = {}
		},
		onValidateQuantity() {
			if (utilities.objectValidate(this.selectedRecord, 'selectedRowKeys', false) && this.selectedRecord.selectedRowKeys.length) {
				let errorProducts = []

				this.selectedRecord.selectedRowKeys.forEach((key) => {
					if (this.localOutboundOrder.order[key].quantity > this.actualOutboundOrder.order[key].quantity) {
						errorProducts.push(`<b>${this.actualOutboundOrder.order[key].product.label} <small>(max. ${this.actualOutboundOrder.order[key].quantity})</small></b>`)
					}
				})

				if (errorProducts.length) {
					Swal.fire({
						title: 'Atención',
						html: `No se pueden devolver más unidades de las que se solicitaron en este proceso.<br> ${errorProducts.join('<br>')}`,
						icon: 'warning',
					})
				} else {
					Swal.fire({
						title: 'Atención',
						html: `Confirmo que se está reingresando el producto al almacén`,
						icon: 'warning',
						showCancelButton: true,
						reverseButtons: true,
						confirmButtonColor: '#41b883',
						confirmButtonText: 'Sí, continuar',
						cancelButtonColor: '#f5222e',
						cancelButtonText: 'No, cancelar',
					}).then(async (result) => {
						if (result.isConfirmed) {
							//
							let payload = {
								...this.localOutboundOrder,
								order: [],
							}
							this.selectedRecord.selectedRowKeys.forEach((key) => {
								payload.order.push({
									...this.localOutboundOrder.order[key],
									label: this.localOutboundOrder.order[key].product.label,
								})
							})
							await this.$store.dispatch('warehouse/RETURN_OUTBOUND_ORDER', payload)
							this.$emit('close')
						}
					})
				}
			} else {
				Swal.fire({
					title: 'Atención',
					html: `No se ha seleccionado producto a devolver`,
					icon: 'warning',
				})
			}
		},
	},
	watch: {
		actualOutboundOrder: {
			deep: true,
			handler(newData) {
				if (utilities.objectValidate(newData, 'order', false)) {
					this.localOutboundOrder = _.cloneDeep(newData)
				}
			},
		},
	},
}
</script>
